var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { wait } from '../utils';
export default class Queue {
    constructor(writer, texts, interval = 800, loop = false) {
        this.isStopped = false;
        this.isLooping = false;
        this.loopInterval = 0;
        this.index = -1;
        this.writer = writer;
        this.interval = interval;
        // Setup texts
        if (Array.isArray(texts))
            this.texts = texts;
        else {
            // If passed html element
            // get child paragraphs as sequent texts to write
            let el;
            if (typeof texts === 'object')
                el = texts;
            else
                el = document.querySelector(texts);
            this.texts = [];
            el === null || el === void 0 ? void 0 : el.childNodes.forEach(node => {
                const { tagName, innerHTML } = node;
                tagName === 'P' &&
                    innerHTML !== undefined &&
                    this.texts.push(innerHTML);
            });
        }
        // Setup looping settings
        if (typeof loop === 'boolean')
            this.isLooping = loop;
        else if (typeof loop === 'function')
            this.endCallback = loop;
        else {
            this.isLooping = true;
            this.loopInterval = loop;
        }
        this.loop();
    }
    stop() {
        this.isStopped = true;
    }
    resume() {
        this.index--;
        this.isStopped = false;
        this.writer.state.isPaused = false;
        this.loop();
    }
    loop() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.texts.length)
                return;
            this.index++;
            if (this.index >= this.texts.length) {
                if (this.isLooping) {
                    yield wait(this.loopInterval);
                    this.index = 0;
                }
                else
                    return (_a = this.endCallback) === null || _a === void 0 ? void 0 : _a.call(this, this.writer.string, this.writer.getWriterData('SUCCESS', "The queue has reached it's end."));
            }
            if (this.isStopped || this.writer.state.isPaused)
                return;
            const result = yield this.writer.manageWriting(this.texts[this.index]);
            if ((result === null || result === void 0 ? void 0 : result.status) !== 'SUCCESS' || this.writer.state.isPaused)
                return;
            yield wait(this.interval);
            this.loop();
        });
    }
}
