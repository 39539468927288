var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { promiseWhile } from '../../utils';
export default function prepLettersPlaylist(playList, playOptions) {
    var _a;
    const { charTable, state, options } = this, reverse = (_a = playOptions === null || playOptions === void 0 ? void 0 : playOptions.reverse) !== null && _a !== void 0 ? _a : false, charTableCopy = reverse ? [...charTable] : [...charTable].reverse();
    // Char executor - runs a loop, typing one char at a time
    // It is possible to run multiple of them at the same time
    const executor = () => __awaiter(this, void 0, void 0, function* () {
        let lastResult = true, ended = false;
        const loop = () => __awaiter(this, void 0, void 0, function* () {
            var _b;
            const lastChar = charTableCopy.pop();
            if (!lastChar)
                ended = true;
            else
                lastResult = (_b = (yield lastChar.type())) !== null && _b !== void 0 ? _b : false;
        });
        yield promiseWhile(() => !ended && lastResult && !state.isPaused, loop);
        return ended && lastResult && !state.isPaused;
    });
    // Add as many executors as needed to the playList
    for (let n = 0; n < options.oneAtATime; n++) {
        playList.push(executor());
    }
}
