var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { random, deleteRandom, wait, promiseWhile, coinFlip, isSpecialChar, animateWithClass, } from '../utils';
export default class Char {
    constructor(writer, l, gl, initialGhosts = '', specialType, index) {
        this.ghosts = [[], []];
        this.stop = false;
        this.afterGlitchChance = 0;
        this.writer = writer;
        const { options } = writer;
        this.index = index;
        this.l = l;
        this.gl = gl;
        this.specialType = specialType;
        this.ghosts[0] = [...initialGhosts];
        this.writer.state.nGhosts += initialGhosts.length;
        this.stepsLeft = options.steps;
        if (specialType === 'tag')
            this.stepsLeft = 0;
        else if (isSpecialChar(gl))
            this.specialType = 'whitespace';
        this.afterGlitchChance =
            (options.ghostChance + options.changeChance) / 3.7;
        if (writer.options.letterize) {
            this.els = {
                ghostsBeforeEl: document.createElement('span'),
                letterEl: document.createElement('span'),
                ghostsAfterEl: document.createElement('span'),
            };
            this.els.ghostsBeforeEl.className = 'gw-ghosts';
            this.els.ghostsAfterEl.className = 'gw-ghosts';
            this.els.letterEl.className = 'gw-letter';
        }
    }
    get string() {
        const { l, ghosts } = this;
        return ghosts[0].join('') + l + ghosts[1].join('');
    }
    get finished() {
        const { l, gl, ghosts } = this;
        return ((l === gl && ghosts[0].length === 0 && ghosts[1].length === 0) ||
            this.specialType === 'tag');
    }
    writeToElement() {
        if (!this.els)
            return;
        const { ghostsBeforeEl, ghostsAfterEl, letterEl, charEl } = this.els;
        letterEl.innerHTML = this.l;
        ghostsBeforeEl.textContent = this.ghosts[0].join('');
        ghostsAfterEl.textContent = this.ghosts[1].join('');
        charEl && animateWithClass(charEl, 'gw-changed');
    }
    set spanElement(el) {
        if (!this.els)
            return;
        this.els.charEl = el;
        this.appendChildren();
    }
    appendChildren() {
        var _a, _b;
        (_b = (_a = this.els) === null || _a === void 0 ? void 0 : _a.charEl) === null || _b === void 0 ? void 0 : _b.append(this.els.ghostsBeforeEl, this.els.letterEl, this.els.ghostsAfterEl);
        this.writeToElement();
    }
    type() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const { options, state, emiter } = this.writer;
            if (this.specialType === 'tag') {
                this.l = this.gl;
                emiter.call('step');
                state.progress.increase();
                return true;
            }
            const loop = () => __awaiter(this, void 0, void 0, function* () {
                yield wait(options.getInterval(this));
                const lastString = this.string;
                this.step();
                if (lastString !== this.string) {
                    emiter.call('step');
                    this.writeToElement();
                }
                !options.endless && this.stepsLeft--;
            });
            yield wait(options.getDelay(this));
            yield promiseWhile(() => (!this.finished || options.endless) &&
                !state.isPaused &&
                !this.stop, loop);
            if (this.finished) {
                state.progress.increase();
                (_b = (_a = this.els) === null || _a === void 0 ? void 0 : _a.charEl) === null || _b === void 0 ? void 0 : _b.classList.add('gw-finished');
                (_c = this.els) === null || _c === void 0 ? void 0 : _c.letterEl.classList.remove('gw-glitched');
            }
            return this.finished;
        });
    }
    step() {
        var _a, _b;
        const { writer } = this;
        if ((this.stepsLeft > 0 && this.l !== this.gl) ||
            (coinFlip(this.afterGlitchChance) &&
                this.specialType !== 'whitespace') ||
            writer.options.endless) {
            /**
             * IS GROWING
             */
            const { ghostChance, changeChance } = writer.options;
            if (coinFlip(ghostChance)) {
                if (writer.state.ghostsInLimit)
                    this.addGhost();
                else
                    this.removeGhost();
            }
            if (coinFlip(changeChance)) {
                (_a = this.els) === null || _a === void 0 ? void 0 : _a.letterEl.classList.add('gw-glitched');
                this.l = writer.options.getGlyph(this);
            }
        }
        else if (!this.finished) {
            /**
             * IS SHRINKING
             */
            (_b = this.els) === null || _b === void 0 ? void 0 : _b.letterEl.classList.remove('gw-glitched');
            this.l = this.gl;
            this.removeGhost();
        }
    }
    addGhost() {
        const { writer, ghosts } = this, l = writer.options.getGlyph(this);
        writer.state.nGhosts++;
        coinFlip() ? insertGhost(ghosts[0], l) : insertGhost(ghosts[1], l);
    }
    removeGhost() {
        const { writer, ghosts } = this, deleted = coinFlip() && ghosts[0].length > 0
            ? deleteRandom(ghosts[0])
            : deleteRandom(ghosts[1]);
        if (deleted)
            writer.state.nGhosts--;
    }
}
function insertGhost(ghostsArray, ghost) {
    const { length } = ghostsArray;
    ghostsArray.splice(random(0, length, 'floor'), 0, ghost);
}
