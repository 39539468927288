var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { promiseWhile, wordsRgx } from '../../utils';
/**
 * ONE WORD AT A TIME
 */
export default function prepWordsPlaylist(playList) {
    const { charTable, state } = this;
    // indexes of chars in chartable that are normal letters
    const indexes = [];
    let wordArray;
    /**
     * Scope for separating words
     */
    {
        const filteredTable = charTable.filter((char, i) => {
            if (char.specialType === 'tag' ||
                char.specialType === 'html_entity')
                return false;
            indexes.push(i);
            return true;
        }), 
        // I want to only find words in the normal text
        text = filteredTable.map(char => char.gl).join('');
        wordArray = text.match(wordsRgx);
    }
    /**
     * Scope for greating groups
     */
    const charGroups = [];
    {
        // set of index variables
        // fi - index of (not) filtered chars
        // gi - index of all chars
        // sgi - steady gi - for detecting when gi grows more than 1
        let fi = -1, ai = -1, sgi = -1;
        const lastGroup = () => charGroups[charGroups.length - 1];
        wordArray === null || wordArray === void 0 ? void 0 : wordArray.forEach(word => {
            charGroups.push([]);
            [...word].forEach(() => {
                fi++;
                ai = indexes[fi];
                sgi++;
                // handling filtered chars before current char
                if (sgi !== ai) {
                    for (let i = sgi; i < ai; i++) {
                        lastGroup().push(charTable[i]);
                    }
                    sgi = ai;
                }
                lastGroup().push(charTable[ai]);
            });
        });
        // Adds the rest of the chartable chars
        if (!charGroups.length)
            charGroups.push([]);
        for (let i = ai + 1; i < charTable.length; i++) {
            lastGroup().push(charTable[i]);
        }
    }
    // so i can use .pop() and get the first group
    charGroups.reverse();
    let lastResult = true, ended = false;
    const loop = () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const group = charGroups.pop();
        if (!group)
            return (ended = true);
        const groupPromises = group.map(char => char.type());
        lastResult =
            (_a = (yield Promise.all(groupPromises)).every(result => result)) !== null && _a !== void 0 ? _a : false;
    });
    const executor = () => __awaiter(this, void 0, void 0, function* () {
        yield promiseWhile(() => !ended && lastResult && !state.isPaused, loop);
        return ended && lastResult && !state.isPaused;
    });
    playList.push(executor());
}
